import React, {useEffect, useState} from 'react'
import logo from './logo.svg';
import './App.css';
import AddInterestListPage from './pages/addInterestList';

function App() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                console.log("use efffect");
                const resp = await fetch('/api/interestList');
                if (resp.ok) {
                    const data = await resp.text();
                    console.log('got data', data);
                    setData(data);
                } else {
                    console.log("failed to get response");
                }
            } catch (error) {
                console.log("got error ", error);
            }
        };
	    
        getData();
    }, []);

    return (
        <div className="App">
            <header className="App-header">
	    This is a website!!!! hello world!!!Let's go {data} ~
            </header>
            <AddInterestListPage />
        </div>
    );
}

export default App;
