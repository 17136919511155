import React, { useState } from 'react';

function AddInterestListPage() {
    const [email, setEmail] = useState('');

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (event) => {
        console.log('add interest list', email);
        event.preventDefault();

        try {
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email}),
            }
            const response = await fetch('/api/interestList', request);
            if (response.ok) {
                let data = await response.text();
 
                console.log('add interested list ok ', data);
            } else {
                console.log('error add interest list');
            }
        } catch (error) {
            console.error('error add interest list page', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label className='mr-2 text-black'>
                Add to interested list
            </label>
            <input
                type='email' value={email} 
                onChange={handleInputChange}
                required
                className='border border-gray-300 px-4 py-2 rounded-md mt-2 text-black'
            />
            <button type="submit"
                className='bg-blue-500 text-white px-6 py-3 rounded-md mt-2 ml-2'
            >
                Submit
            </button>
        </form>        
    )
}

export default AddInterestListPage;
